import { AfterContentInit, Component, ContentChildren, forwardRef, Input, QueryList } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator
} from '@angular/forms';
import { CalendarTypeView } from 'primeng/calendar';
import { LayoutService } from '../../../services';
import { BehaviorSubject, delay, map, Observable } from 'rxjs';
import { dateToIso, isoToDate } from '../../../util';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
  selector: 'raily-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements AfterContentInit, ControlValueAccessor, Validator {
  @Input() required = false;
  // hack for required validation check bug when disableSwitch is used
  @Input()
  set isRequired(value: boolean) {
    this.required = value;
  }
  @Input() label?: string;
  @Input() type = 'text';
  @Input() showDivider = false;
  @Input() disableSwitch = false;
  @Input() options?: any[] = [];
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() placeholder?: string;
  @Input() dateType: CalendarTypeView = 'date';
  @Input() radioLayout = 'horizontal';
  @Input() isoDate?: boolean = true;
  @Input() mask?: string;
  @Input() autocompleteSearchKeys: string[] = [];

  value?: any;
  isDisabled = false;
  hasContent = false;
  _disableSwitchState = false;

  private valueChangesSubject = new BehaviorSubject(this.value);
  valueChanges: Observable<any> = this.valueChangesSubject.pipe(
    delay(1)
  );

  suggestions: any[] = [];

  get disableSwitchState() {
    return this._disableSwitchState;
  }

  set disableSwitchState(value: boolean) {
    this._disableSwitchState = value;
    if (!value) {
      this.onValueChange(null);
    }
  }

  get dateFormat() {
    switch (this.dateType) {
      case 'date': return 'dd/mm/yy';
      case 'month': return 'mm/yy';
      case 'year': return 'yy';
    }
  }

  onChange = (_: any) => {};
  onTouch = () => {};

  @ContentChildren('content') content!: QueryList<any>;

  constructor(public layoutService: LayoutService) {}

  ngAfterContentInit() {
    this.hasContent = this.content && this.content.length > 0;
  }

  writeValue(value: any): void {
    if (this.type === 'date' && this.isoDate && value) {
      const formatted = isoToDate(value);
      this.value = formatted;
    } else {
      this.value = value;
    }
    if (this.value) {
      this.disableSwitchState = true;
    }
  }

  onValueChange(value: any) {
    this.value = value;
    const output = (this.type === 'date' && this.isoDate && value) ? dateToIso(value) : value;
    this.onChange(output);
    this.valueChangesSubject.next(output);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // Add additional logic if you need to disable specific elements inside your component
  }

  validate(control?: AbstractControl): ValidationErrors | null {
    if (this.disableSwitch && !this._disableSwitchState) {
      return null;
    }
    if (this.required && (this.value === null || this.value === '' || this.value === undefined)) {
      return { required: true };
    }
    return null;
  }

  search(event: AutoCompleteCompleteEvent) {
    this.suggestions = (this.options && this.options.filter(option => {
      return this.autocompleteSearchKeys.map(key => option[key]).some(value => value.toLowerCase().includes(event.query.toLowerCase()))
    })) ?? [];
  }

  onNumberInputFocus() {
    if (this.value === 0) {
      this.value = null;
    }
  }
}
