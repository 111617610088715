<raily-page-layout [backButton]="'router'" [title]="resolvedTitle$ | async">

  <ng-container actions>

    <ng-content select="[actions]"></ng-content>

    <ng-container *ngIf="['edit', 'create'].includes((detailsService.mode$ | async)!)">
      <p-button
        icon="pi pi-times"
        [label]="'BUTTON.CANCEL' | transloco"
        (click)="cancel()">
      </p-button>

      <p-button
        icon="pi pi-check"
        [disabled]="!validationService.isValid"
        [label]="'BUTTON.SAVE' | transloco"
        (click)="save()">
      </p-button>
    </ng-container>

    <ng-container *ngIf="['details'].includes((detailsService.mode$ | async)!)">
      <p-button
        *ngIf="showEditButton"
        icon="pi pi-file-edit"
        [label]="'BUTTON.EDIT' | transloco"
        (click)="edit()">
      </p-button>
    </ng-container>
  </ng-container>

  <ng-container content>
    <ng-content></ng-content>
  </ng-container>

</raily-page-layout>

