import { Directive, TemplateRef } from '@angular/core';
import { LayoutService } from '../../services';

@Directive({
  selector: '[railyActionBar]',
})
export class ActionBarDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private layoutService: LayoutService
  ) {
    this.layoutService.setActionBarTemplate(templateRef);
  }
}
