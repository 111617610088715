export interface AppPermissions {
  CoreOrganisationAdministrator: string;
  CoreUserManagement: string;
  CoreStructuresManagement: string;
  CoreStructuresRead: string;
  AssetManagementCreateAssets: string;
  AssetManagementAssetsRead: string;
  AssetManagementTechnicalWrite: string;
  AssetManagementTechnicalRead: string;
  AssetManagementDocumentsWrite: string;
  AssetManagementDocumentsRead: string;
  AssetManagementDamageReportsWrite: string;
  AssetManagementDamageReportsRead: string;
  DamageReportsCreate: string;
  DamageReportsRead: string;
  RailyAdministrationOrganisationsManagement: string;
  RailyAdministrationUsers: string;
}

export const AppPermissionsValues: AppPermissions = {
  CoreOrganisationAdministrator: 'P_UI_Core_OrganisationAdministrator',
  CoreUserManagement: 'P_UI_Core_UserManagement',
  CoreStructuresManagement: 'P_UI_Core_StructuresManagement',
  CoreStructuresRead: 'P_UI_Core_StructuresRead',
  AssetManagementCreateAssets: 'P_UI_AssetManagement_CreateAssets',
  AssetManagementAssetsRead: 'P_UI_AssetManagement_AssetsRead',
  AssetManagementTechnicalWrite: 'P_UI_AssetManagement_TechnicalWrite',
  AssetManagementTechnicalRead: 'P_UI_AssetManagement_TechnicalRead',
  AssetManagementDocumentsWrite: 'P_UI_AssetManagement_DocumentsWrite',
  AssetManagementDocumentsRead: 'P_UI_AssetManagement_DocumentsRead',
  AssetManagementDamageReportsWrite: 'P_UI_AssetManagement_DamageReportsWrite',
  AssetManagementDamageReportsRead: 'P_UI_AssetManagement_DamageReportsRead',
  DamageReportsCreate: 'P_UI_DamageReports_Create',
  DamageReportsRead: 'P_UI_DamageReports_Read',
  RailyAdministrationOrganisationsManagement: 'P_ADM_ORG_OrganisationsManagement',
  RailyAdministrationUsers: 'P_ADM_ORG_Users',
};

export interface Permission {
  name: string;
  code: string;
  active?: boolean;
  permissions: string[];
}
