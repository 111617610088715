import { AfterContentInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService } from '@raily/auth';
import {
  ErrorService,
  LanguageService,
  LayoutService,
  LoadingStateService,
  RailyError, ToastService
} from '@raily/shared';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, delay } from 'rxjs';

@Component({
  selector: 'raily-app',
  templateUrl: './raily-app.component.html',
  styleUrl: './raily-app.component.scss',
  providers: [MessageService]
})
export class RailyAppComponent implements OnInit, AfterContentInit {
  @ViewChild('actionBar', { read: ViewContainerRef }) actionBarContainer!: ViewContainerRef;

  constructor(
    public authService: AuthService,
    public loadingStateService: LoadingStateService,
    public layoutService: LayoutService,
    private errorService: ErrorService,
    private messageService: MessageService,
    private languageService: LanguageService,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.authService.loggedIn$.subscribe(loggedIn => {
      if (!loggedIn && !document.location.href.includes('reset-password')) {
        this.router.navigate(['auth', 'login']);
      }
    });

    this.errorService.errors$.subscribe(error => this.showErrorNotification(error));

    this.toastService.toast$.subscribe(toast => {
      const detail = toast.messageI18n ? this.languageService.translate(toast.messageI18n) : undefined;
      this.messageService.add({
        severity: toast.severity,
        summary: this.languageService.translate(toast.titleI18n),
        detail
      });
    })
  }

  ngAfterContentInit() {
    combineLatest([
      this.layoutService.actionBarTemplate$,
      this.layoutService.isMobile$
    ]).subscribe(([template, isMobile]) => {
      this.actionBarContainer?.clear();
      this.actionBarContainer?.createEmbeddedView(template);
    })
  }

  private showErrorNotification(error: RailyError) {
    this.messageService.add({
      severity: 'error',
      summary: this.languageService.translate('ERROR.TOAST_TITLE'),
      detail: this.languageService.translate(error.i18n)
    });
  }
}
