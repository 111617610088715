import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { SharedModule } from '@raily/shared';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { StyleClassModule } from 'primeng/styleclass';
import { SettingsComponent } from './components/settings/settings.component';
import { RailyAppComponent } from './components/raily-app/raily-app.component';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslocoModule,
    StyleClassModule,
    NgOptimizedImage,
    SidebarModule,
    ToastModule,
    LeafletModule
  ],
  declarations: [SidebarComponent, SettingsComponent, RailyAppComponent],
  exports: [SidebarComponent, RailyAppComponent],
})
export class CoreModule {}
