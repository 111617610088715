import { Injectable, TemplateRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  isMobile$!: Observable<boolean>;
  isMobile = false;
  sidebarOpen = false;

  private actionBarTemplateSubject = new Subject<TemplateRef<any>>();
  actionBarTemplate$ = this.actionBarTemplateSubject.asObservable();


  constructor(
    public breakpointObserver: BreakpointObserver
  ) {
    this.isMobile$ = this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
    ]).pipe(
      map(result => result.matches)
    );
    this.isMobile$.subscribe(isMobile => this.isMobile = isMobile);
  }

  toggleSidebar(open?: boolean) {
    if (open !== undefined) {
      this.sidebarOpen = open;
    } else {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }

  setActionBarTemplate(template: TemplateRef<any>) {
    this.actionBarTemplateSubject.next(template);
  }
}
