import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import { OrganizationSettings, TranslatedProperty } from '../../../model';
import { ORGANIZATION_SETTINGS } from '../../../di';

@Component({
  selector: 'raily-multilang-input',
  templateUrl: './multilang-input.component.html',
  styleUrls: ['./multilang-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultilangInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultilangInputComponent),
      multi: true
    }
  ]
})
export class MultilangInputComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() required = false;
  @Input() label!: string;
  @Input() type = 'text';
  @Input() showDivider = false;

  value?: TranslatedProperty;
  isDisabled = false;
  translationsState: { [key: string]: { code: string, active: boolean, value: string } } = {};

  onChange = (_: TranslatedProperty) => {};
  onTouch = () => {};

  get translationStates(): { code: string, active: boolean, value: string }[] {
    return Object.keys(this.translationsState).map(key => this.translationsState[key]);
  }

  get activeTranslations() {
    return this.translationStates.filter(translation => translation.active);
  }

  constructor(@Inject(ORGANIZATION_SETTINGS) public organizationSettings: OrganizationSettings) {}

  ngOnInit(): void {

    for (const lang of this.organizationSettings.langs) {
      this.translationsState[lang] = { code: lang, active: false, value: '' };
    }

    if (!this.value) {
      this.onChange({ translations: [] });
    }
  }

  onValueChange(code: string, event: Event) {
    this.translationsState[code].value = (event.target as HTMLInputElement).value;
    const updated: TranslatedProperty = { translations: [] };
    for (const translated of this.translationStates) {
      updated.translations.push({ code: translated.code, value: translated.value });
    }
    this.onChange(updated);
  }

  writeValue(value: TranslatedProperty): void {
    this.value = value;
    for (const translation of this.value?.translations || []) {
      this.translationsState[translation.code] = {
        code: translation.code,
        active: this.translationsState[translation.code]?.active || !!translation.value || translation.code === this.organizationSettings.defaultLang,
        value: translation.value
      }
    }
    this.translationsState[this.organizationSettings.defaultLang].active = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // Add additional logic if you need to disable specific elements inside your component
  }

  validate(control: AbstractControl): ValidationErrors | null {
    // Add validation logic here
    if (this.required && (this.value == null)) {
      return { required: true };
    }
    return null;
  }
}
