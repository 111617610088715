<div class="flex flex-column gap-2 pb-4 mb-4" [ngClass]="{ divider: showDivider }">
  <div class="flex flex-row justify-content-between align-items-center">
    <label class="font-medium text-900">
      {{ label | transloco }}
      <span *ngIf="required">*</span>
    </label>
    <div class="flex flex-row justify-content-end gap-2">
      <p-toggleButton
        *ngFor="let translation of translationStates"
        [onLabel]="translation.code | uppercase"
        [offLabel]="translation.code | uppercase"
        [disabled]="translation.code === organizationSettings.defaultLang || isDisabled"
        [(ngModel)]="translationsState[translation.code].active">
      </p-toggleButton>
    </div>
  </div>
  <div *ngFor="let translation of activeTranslations" class="flex flex-row justify-content-start w-12 align-items-center gap-3">
    <label class="font-medium text-900 w-1rem" [for]="label + translation.code">{{ translation.code | uppercase }}</label>
    <ng-container [ngSwitch]="type">

      <input
        *ngSwitchCase="'text'"
        pInputText
        type="text"
        class="w-12"
        [name]="label + translation.code"
        [id]="label + translation.code"
        [value]="translationsState[translation.code].value"
        [disabled]="isDisabled"
        (change)="onValueChange(translation.code, $event)"/>

      <textarea
        *ngSwitchCase="'textarea'"
        pInputTextarea
        rows="3"
        class="w-12"
        [name]="label + translation.code"
        [id]="label + translation.code"
        [value]="translationsState[translation.code].value"
        [disabled]="isDisabled"
        (change)="onValueChange(translation.code, $event)">
      </textarea>

    </ng-container>
  </div>
</div>
