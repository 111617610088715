import { Injectable } from '@angular/core';
import { BaseDtoService } from '../dto/base-dto.service';
import { BehaviorSubject, combineLatestWith, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { BaseApiService } from '../api';
import { editableModes, editEntityModes, newEntityModes, readonlyModes } from '../../model';
import { BaseListService } from '../list/base-list.service';

@Injectable()
export class BaseDetailsService<T extends { id: string }, R = T> {

  modeSubject$ = new BehaviorSubject('');
  mode$ = this.modeSubject$.asObservable();
  disabled$!: Observable<boolean>;
  createMode$!: Observable<boolean>;
  editOrDetailsMode$!: Observable<boolean>;

  idSubject$ = new BehaviorSubject('');
  id$ = this.idSubject$.asObservable();

  entitySubject$ = new BehaviorSubject<T>({} as T);
  entity$ = this.entitySubject$.asObservable();
  entity!: T;

  entityInitialized?: boolean;
  overrideId?: string;

  constructor(
    protected apiService: BaseApiService<T, R>,
    protected dtoService: BaseDtoService<T, R>,
    protected listService?: BaseListService<any, any>
  ) {

    this.id$.pipe(
      combineLatestWith(this.mode$),
      switchMap(([id, mode]) => {
        if (['details', 'edit'].includes(mode)) {
          return this.apiService.getById(id)
        } else {
          return of(this.dtoService.initModel())
        }
      }),
    ).subscribe(entity => {
      if (this.entityInitialized) {
        return;
      }
      this.entity = entity as T;
      this.entitySubject$.next(entity as T)
    });

    this.disabled$ = this.mode$.pipe(
      map(mode => readonlyModes.includes(mode))
    );

    this.createMode$ = this.mode$.pipe(
      map(mode => newEntityModes.includes(mode))
    );

    this.editOrDetailsMode$ = this.mode$.pipe(
      map(mode => editEntityModes.includes(mode))
    );
  }

  save(): Observable<T> {
    return this.mode$.pipe(
      take(1),
      map(mode => ({ mode, dto: this.dtoService.toDto(this.entity) })),
      switchMap(({ mode, dto }) => {
        if (mode === 'edit') {
          return this.apiService.update(this.overrideId ?? this.entity.id, dto);
        } else {
          return this.apiService.create(dto)
        }
      }),
      map(entity => {
        if (this.overrideId) {
          entity.id = this.overrideId;
        }
        return entity;
      }),
      tap(() => this.refreshList())
    );
  }

  refreshObservable(): void {
    this.entitySubject$.next(this.entity);
  }

  refreshList() {
    if (this.listService) {
      this.listService.refresh();
    }
  }
}
