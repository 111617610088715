import { NavigationGroup } from '@raily/core';
import { AppPermissionsValues } from '@raily/auth';
import { NAVIGATION } from '@raily/shared';

export const navigationGroups: NavigationGroup[] = [
  {
    name: 'Administration',
    i18nKey: 'NAVIGATION.ADMINISTRATION.GROUP_NAME',
    icon: 'assets/icons/certificate.svg',
    items: [
      {
        name: 'Organization',
        i18nKey: 'NAVIGATION.ADMINISTRATION.ORGANIZATION',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator],
        icon: 'assets/icons/dashboard.svg',
        link: '/organisation/details/my',
      },
      {
        name: 'Users',
        i18nKey: 'NAVIGATION.ADMINISTRATION.USERS',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator],
        icon: 'assets/icons/group.svg',
        link: '/users',
      },
      {
        name: 'Roles',
        i18nKey: 'NAVIGATION.ADMINISTRATION.ROLES',
        permissions: [AppPermissionsValues.CoreOrganisationAdministrator,],
        icon: 'assets/icons/shield.svg',
        link: '/roles',
      },
      {
        name: 'Document types',
        i18nKey: 'NAVIGATION.ASSETS.DOCUMENT_TYPES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        icon: 'assets/icons/book.svg',
        link: '/document-types'
      }
    ],
  },
  {
    name: 'Templates',
    i18nKey: 'NAVIGATION.TEMPLATES.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    items: [
      {
        name: 'Asset Templates',
        i18nKey: 'NAVIGATION.TEMPLATES.ASSET_TEMPLATES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        icon: 'assets/icons/3d_box.svg',
        link: '/asset-templates',
      },
      {
        name: 'Elements',
        i18nKey: 'NAVIGATION.TEMPLATES.ELEMENTS',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        icon: 'assets/icons/book.svg',
        link: '/elements',
      },
      {
        name: 'Attributes',
        i18nKey: 'NAVIGATION.TEMPLATES.ATTRIBUTES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        icon: 'assets/icons/certificate.svg',
        link: '/attributes',
      },
      {
        name: 'Dictionaries',
        i18nKey: 'NAVIGATION.TEMPLATES.DICTIONARIES',
        permissions: [AppPermissionsValues.CoreStructuresManagement],
        icon: 'assets/icons/book_open_alt.svg',
        link: '/dictionaries',
      },
    ],
  },
  {
    name: 'Assets',
    i18nKey: 'NAVIGATION.ASSETS.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    items: [
      {
        name: 'Locomotives',
        i18nKey: 'NAVIGATION.ASSETS.LOCOMOTIVES',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        icon: 'assets/icons/wagon_icon.svg',
        link: '/assets/list/Locomotive',
      },
      {
        name: 'Wagons',
        i18nKey: 'NAVIGATION.ASSETS.WAGONS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        icon: 'assets/icons/wagon_icon.svg',
        link: '/assets/list/Wagon',
      },
      {
        name: 'Wheelsets',
        i18nKey: 'NAVIGATION.ASSETS.WHEELSETS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        icon: 'assets/icons/wheelset.svg',
        link: '/assets/list/Wheelset',
      },
      // {
      //   name: 'Asset Advanced Search',
      //   i18nKey: 'NAVIGATION.ASSETS.ADVANCED_SEARCH',
      //   permissions: [AppPermissionsValues.ManageAssetTemplates],
      //   icon: 'assets/icons/book.svg',
      //   link: '/assets/advanced-search',
      // },
      {
        name: 'Create Assets',
        i18nKey: 'NAVIGATION.ASSETS.CREATE',
        permissions: [AppPermissionsValues.AssetManagementCreateAssets],
        icon: 'assets/icons/certificate.svg',
        link: '/assets/create',
      },
      {
        name: 'Wagons Dashboard',
        i18nKey: 'NAVIGATION.ASSETS.WAGONS_DASHBOARD',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        icon: 'pi pi-desktop',
        link: '/assets/dashboard/Wagon',
      },
      {
        name: 'Telematics',
        i18nKey: 'NAVIGATION.ASSETS.TELEMATICS',
        permissions: [AppPermissionsValues.AssetManagementAssetsRead],
        icon: 'assets/icons/map.svg',
        link: '/assets/telematics',
      },
    ],
  },
  {
    name: 'Maintenance',
    i18nKey: 'NAVIGATION.MAINTENANCE.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    mobile: true,
    items: [
      {
        name: 'Damage reports',
        i18nKey: 'NAVIGATION.MAINTENANCE.DAMAGE_REPORTS',
        permissions: [AppPermissionsValues.DamageReportsRead],
        icon: 'assets/icons/report.svg',
        link: '/damage-reports',
        mobile: true,
        exactMatch: true,
      },
      {
        name: 'Create damage report',
        i18nKey: 'NAVIGATION.MAINTENANCE.CREATE_DAMAGE_REPORT',
        permissions: [AppPermissionsValues.DamageReportsRead],
        icon: 'assets/icons/new_report.svg',
        link: '/damage-reports/create',
        mobile: true,
        exactMatch: true,
      },
    ],
  },
  {
    name: 'Transport',
    i18nKey: 'NAVIGATION.TRANSPORT.GROUP_NAME',
    icon: 'assets/icons/dashboard.svg',
    mobile: true,
    items: [
      {
        name: 'Train composition',
        i18nKey: 'NAVIGATION.TRANSPORT.TRAIN_COMPOSITION',
        permissions: [AppPermissionsValues.DamageReportsRead],
        icon: 'assets/icons/report.svg',
        link: '/train-composition',
        mobile: true,
      },
    ],
  },
];

export const navigationProvider = {
  provide: NAVIGATION,
  useValue: navigationGroups
};
