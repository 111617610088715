import { Component, Input } from '@angular/core';
import { LayoutService } from '../../../services';

@Component({
  selector: 'raily-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  @Input() title?: string | null;
  constructor(public layoutService: LayoutService) {}
}
