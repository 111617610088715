import { Component, forwardRef, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { BehaviorSubject, delay, map, Observable, Subject, switchMap } from 'rxjs';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { ApiSearchAutocompleteService } from '../../../services/api/api-search-autocomplete.service';

@Component({
  selector: 'raily-search-autocomplete',
  templateUrl: './search-autocomplete.component.html',
  styleUrl: './search-autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SearchAutocompleteComponent),
      multi: true
    }
  ]
})
export class SearchAutocompleteComponent implements ControlValueAccessor, Validator {
  @Input() label!: string;
  @Input() required = false;
  @Input() url!: string;
  @Input() apiSearch = false;
  @Input() labelField!: string;
  @Input() searchFields?: string[];
  @Input() dropdown = false;
  @Input() withLocation = false;
  @Input() showRequiredAsterisks = true;
  @Input() additionalData?: any;
  @Input() filterFunction?: (options: any[]) => any[];

  value?: any;
  isDisabled = false;

  filterOptions = new Subject<AutoCompleteCompleteEvent>();

  options$: Observable<any[]> = this.filterOptions.pipe(
    switchMap((event) => this.apiSearch ?
      this.apiSearchAutocompleteService.searchApi(event?.query?.toLowerCase(), this.url, this.withLocation, this.additionalData) :
      this.apiSearchAutocompleteService.searchLocal(event?.query?.toLowerCase(), this.url, this.searchFields)
    ),
    map(options => this.filterFunction ? this.filterFunction(options) : options)
  ) as Observable<any[]>;

  onChange = (_: any) => {};
  onTouch = () => {};

  private valueChangesSubject = new BehaviorSubject(this.value);
  valueChanges: Observable<any> = this.valueChangesSubject.pipe(
    delay(1)
  );

  constructor(private apiSearchAutocompleteService: ApiSearchAutocompleteService) {}

  writeValue(value: any): void {
    this.value = value;
    // Add additional logic if needed
  }

  onValueChange(value: any) {
    this.onChange(value);
    this.valueChangesSubject.next(value);
  }

  onFilterChange(event: AutoCompleteCompleteEvent) {
    this.onChange(event.query);
    this.filterOptions.next(event);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    // Add additional logic if you need to disable specific elements inside your component
  }

  validate(control?: AbstractControl): ValidationErrors | null {
    // Add validation logic here
    if (this.required && (this.value == null)) {
      return { required: true };
    }
    return null;
  }
}
