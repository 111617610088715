<div class="table-card" [class.fixed-layout]="fixedLayout">
  <p-table
    #table
    [value]="items"
    [scrollable]="true"
    [globalFilterFields]="filterFields"
    [rowTrackBy]="trackBy"
    [scrollHeight]="finalScrollHeight()">

    <ng-template pTemplate="caption">
      <div class="flex">
        <raily-table-search class="ml-auto" [table]="table"></raily-table-search>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let column of columns"
          [ngClass]="{ 'table-icon': getColumnType(column) === ColumnType.Boolean }"
          [class]="column.width ? 'w-' + column.width : ''"
          [pSortableColumn]="(sortable && getColumnSortable(column)) ? column.propertyPath : undefined"
        >
          <div class="flex flex-row align-items-center">
            {{ column.titleI18n | transloco }}<p-sortIcon *ngIf="sortable && getColumnSortable(column)" [field]="column.propertyPath" />
          </div>
        </th>

        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr style="height:46px">

        <td
          class="table-cell"
          *ngFor="let column of columns; let index = index"
          [ngClass]="{ 'table-icon': getColumnType(column) === ColumnType.Boolean, 'cursor-pointer': column.callback }"
          [class]="'w-' + column.width"
          (click)="column.callback && column.callback(item, index, column)"
        >
          <ng-container [ngSwitch]="getColumnType(column)">

            <span *ngSwitchCase="ColumnType.Text">
              {{ (column.transform ? column.transform(getValue(item, column.propertyPath)) : getValue(item, column.propertyPath)) ?? '-' }}
            </span>

            <raily-active-icon
              *ngSwitchCase="ColumnType.Boolean"
              [active]="getValue(item, column.propertyPath)"
            ></raily-active-icon>

            <span *ngSwitchCase="ColumnType.Date">
              {{ (getValue(item, column.propertyPath) | date:(column.dateFormat ?? 'dd/MM/yy')) ?? '-' }}
            </span>
          </ng-container>
        </td>

        <td>
          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
