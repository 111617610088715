import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastSubject = new Subject<ToastData>();
  toast$ = this.toastSubject.asObservable();

  errorToast(titleI18n: string, messageI18n?: string) {
    this.toastSubject.next({ titleI18n, messageI18n, severity: ToastSeverity.Error });
  }
}

export interface ToastData {
  titleI18n: string;
  messageI18n?: string;
  severity?: ToastSeverity;
}

export enum ToastSeverity {
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
  Error = 'error'
}
